<template>
  <div>
    <div class="all_container">
      <div class="container">
        <!-- 首页切换 -->
        <div class="changeClassify">
          <div class="title" @click="gohome">{{ $t("other.homePage") }}</div>
          <div>-</div>
          <div class="title">{{ lang == "zh" ? father_zh_name : father_bnm_name }}</div>
          <div>-</div>
          <div>{{ lang == "zh" ? zh_name : bnm_name }}</div>
        </div>

        <!-- 地址选择 -->
        <div class="carousel">
          <div class="carousel_box">
            <div class="carousel_box_left">
              <!-- 地区 -->
              <el-cascader
                v-model="valueCity"
                :options="cityList"
                :props="{ value: 'id', label: 'name' }"
                @change="handleChange"
                clearable
                class="cascader"
                :placeholder="$t('release.selectCity')"
              ></el-cascader>

              <el-select
                v-model="value"
                :placeholder="$t('release.pleaseSelect')"
                @change="chooseType"
                style="margin:0 40px"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="lang == 'zh' ? item.name : item.sp_name"
                  :value="item.id"
                  style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                >
                  <span>
                    {{
                    lang == "zh" ? item.name : item.sp_name
                    }}
                  </span>
                </el-option>
              </el-select>

              <el-input
                type="text"
                :placeholder="$t('broker')"
                v-model="brokerName"
                @blur="getSearchInfo"
                @keyup.enter="getSearchInfo"
              />
            </div>

            <div class="carousel_box_right" @click="delCheck">{{ $t("home.clearChoose") }}</div>
          </div>
        </div>
        <!-- <div class="carousel">
          <div class="carousel_box">
            <div class="left">
              <div class="city">
                {{ $t("home.city") }}
              </div>
              <div class="price" style="margin-bottom: 15px">
                {{ $t("home.search") }}
              </div>
            </div>

            <div class="right">
              <div class="top">
                <div
                  class="city_name"
                  v-for="(item, index) in cityList"
                  :key="index"
                  @click="chooseCity(item, index)"
                  :class="chooseIndex == index ? 'active' : ''"
                >
                  {{ item.name }}
                  <div class="border" v-show="chooseIndex == index"></div>
                </div>
              </div>

              <div class="center">
                <el-checkbox-group
                  v-model="checkList"
                  v-for="(item, index) in checkCityList"
                  :key="index"
                  @change="changeCheck"
                >
                  <el-checkbox :label="item.name"></el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="bottom">
                <div class="left">
                  <input
                    type="text"
                    :placeholder="$t('broker')"
                    v-model="brokerName"
                    @blur="getSearchInfo"
                    @keyup.enter="getSearchInfo"
                  />
                </div>
                <div class="brand">
                  {{ $t("agentType") }}
                  <el-select
                    v-model="value"
                    :placeholder="$t('release.pleaseSelect')"
                    @change="chooseType"
                    style="margin-left: 40px"
                  >
                    <el-option
                      v-for="item in typeList"
                      :key="item.value"
                      :label="lang == 'zh' ? item.name : item.sp_name"
                      :value="item.id"
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                    >
                      <span>{{
                        lang == "zh" ? item.name : item.sp_name
                      }}</span>
                    </el-option>
                  </el-select>
                </div>

                <div class="right" @click="delCheck">
                  {{ $t("home.clearChoose") }}
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <!-- 分类区 -->
        <div class="main_body">
          <!-- 热门经纪人 -->
          <div class="info">
            <!-- <div class="title">
                            <div>热门经纪人</div>
                            <div class="border"></div>
            </div>-->
            <div class="broker_box">
              <div class="info_box" v-for="(item, index) in borkerList" :key="index">
                <div>
                  <div
                    class="broker_img"
                    :style="'background-image:url(' + item.company_logo + ');'"
                  >
                    <div class="broker_img_m"></div>
                    <div class="border_info">
                      <div class="border_info_name">{{ item.company }}</div>
                      <div class="border"></div>
                      <div class="border_info_content">{{ item.company_desc }}</div>
                    </div>
                  </div>

                  <div class="talent_agency">
                    <div class="left">
                      <img :src="item.head_img" alt />
                    </div>
                    <div class="right">
                      <div class="name">{{ item.user_nickname }}</div>
                      <div class="job">{{ lang == "zh" ? item.type_name : item.sp_name }}</div>
                      <div class="num">
                        {{ $t("brokerLicenseNumber") }}：{{
                        item.license_number
                        }}
                      </div>
                      <div class="address">
                        <img src="@/assets/new_home_icons/address_icon.png" alt />
                        {{ item.address }}
                      </div>
                    </div>
                  </div>

                  <div class="btn" @click="goBroker(item)">{{ $t("consultationDetails") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 分页 -->
        <div class="pagination">
          <div class="total">{{ $t("home.gong") }} {{ total }} {{ $t("home.tiao") }}</div>
          <div>
            <el-pagination
              :background="true"
              layout="prev, pager, next"
              :total="total"
              @prev-click="prev"
              @next-click="next"
              :page-size="pageSize"
              @current-change="handleCurrentChange"
              :current-page.sync="pageNo"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classifyStatus: "",
      recommendList: [], //热门推荐列表
      likeList: [], //喜欢列表

      classify: [],
      cityList: [], // 城市列表
      checkCityList: [], // 二级城市列表
      checkList: [], // 多选框选中的城市
      city_id: "", //城市一级id
      city_two_id: [], //城市二级id列表
      chooseIndex: "",

      minprice: "", //最低价格
      maxprice: "", //最高价格

      borkerList: [],

      lang: "",
      vip: "",
      loadingValue: false,
      tap: false,

      brokerName: "", // 搜索专业人士的title

      //分页数据
      pageNo: 1,
      pageSize: 10,
      total: 0,

      pid: "",
      classify_id: "", //父页面传来的id
      zh_name: "",
      bnm_name: "",
      father_zh_name: "",
      father_bnm_name: "",

      typeList: [],
      value: "",
      typeId: "",
      typeLabel: "",

      valueCity: ""
    };
  },
  created() {
    this.classify_id = this.$route.query.classify_id;
    this.pid = this.$route.query.pid;
    this.zh_name = this.$route.query.zh_name;
    this.bnm_name = this.$route.query.bnm_name;
    this.father_zh_name = this.$route.query.father_zh_name;
    this.father_bnm_name = this.$route.query.father_bnm_name;

    console.log(this.$route.query.classify, 123);
    console.log("打开");
    console.log("关闭");
    this.lang = localStorage.getItem("lang");
    // this.getRecommend();
    // this.getAllLike();
    this.getAds(1, 3);
    // this.getAds(2, 4);
    // this.getAds(3, 5);
    // this.getAds(4, 6);
    // this.getAds(5, 7);
    // this.getAds(6, 8);
    // this.getAds(7, 9);

    this.getType();

    this.getCountry();
    this.getSearchInfo();

    console.log(this.$route.params.Classify, "111111");

    // 实现监听localstorage中某个键对应的值的变化
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", e => {
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值
      if (e.key === "lang") {
        this.lang = e.newValue;
      }
    });

    this.getUserInfo();
  },
  watch: {
    $route(val, from) {
      // 拿到目标参数 val.query.id 去请求接口
      console.log(val);
      this.classify_id = val.query.classify_id;
      console.log(this.classify_id, "this.classify_id ");
      this.getSearchInfo();
    }
  },

  mounted() {},
  methods: {
    // 地区选择
    handleChange(e) {
      console.log(e);
      this.city_two_id = e;
      this.getSearchInfo();
    },
    prev() {
      console.log("上一页");
      this.pageNo--;
      this.getSearchInfo();
    },
    next() {
      console.log("下一页");
      this.pageNo++;
      this.getSearchInfo();
    },

    handleCurrentChange(val) {
      this.pageNo = val;
      this.getSearchInfo();
      console.log(`当前页: ${val}`);
    },

    goBorker() {
      this.$router.push({
        path: `/borker_details`,
        query: {
          Classify: 1234
        }
      });
    },
    gohome() {
      this.classifyStatus = 1;
      this.$emit("chaangeClassifyStatus", this.classifyStatus);
    },

    goParticulars(item) {
      this.$router.push({
        path: "/details"
      });
    },
    // tapCity(item, index) {
    //     console.log(item, index);
    //     this.tap = index
    // },

    // 获取本页面列表详情
    getSearchInfo() {
      this.$http
        .getBorkerList({
          pageSize: this.pageSize,
          province_id: this.city_id,
          addr_id: this.city_two_id,
          cate_id: this.classify_id,
          index_cate_id: this.pid,
          search_name: this.brokerName,
          type: this.typeId
        })
        .then(res => {
          if (res.code == 1) {
            console.log(res, "获取本页面详情");
            this.borkerList = res.data.data;
            this.total = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // 轮播图切换事件
    bannerArrChange(item) {
      console.log(item);
      let ads_type = item.ads_type;
      let bind_information_id = item.bind_information_id;
      let href = item.href;
      if (ads_type == 1) {
        if (href) {
          window.open(href);
        }
      } else {
        this.$router.push({
          path: "/detail",
          query: {
            information_id: bind_information_id
          }
        });
      }
    },
    // 获取用户信息
    getUserInfo() {
      this.$http.userInfo().then(res => {
        if (res.code == 1) {
          this.vip = res.data.vip;
        } else if (res.code == -201) {
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 获取轮播图
    getAds(type, typeid) {
      console.log(type, "快捷键了");
      this.$http
        .getAds({
          typeid
        })
        .then(res => {
          if (res.code == 1) {
            switch (type) {
              case 1:
                this.bannerArr1 = res.data;
                break;
              case 2:
                this.bannerArr2 = res.data;
                break;
              case 3:
                this.bannerArr3 = res.data;
                break;
              case 4:
                this.bannerArr4 = res.data;
                break;
              case 5:
                this.bannerArr5 = res.data;
                break;
              case 6:
                this.bannerArr6 = res.data;
                break;
              case 7:
                this.bannerArr7 = res.data;
                break;
            }
          } else {
            this.$message.info(res.msg);
          }
        });
    },

    // 选择代理类型
    chooseType(e) {
      console.log(e);
      this.typeId = e;
      console.log(this.typeId);
      this.getSearchInfo();
    },

    getCountry() {
      this.$http.getCountry().then(res => {
        if (res.code == 1) {
          this.cityList = res.data;
          this.checkCityList = res.data[0].children;
        } else {
          this.$messgae.info(res.msg);
        }
      });
    },

    // 选择城市
    chooseCity(item, index) {
      this.city_two_id = [];
      console.log(item, "二级城市");
      this.chooseIndex = index;
      this.checkCityList = item.children;
      this.city_id = item.id; //一级id
      this.checkList = [];
      this.getSearchInfo();
    },

    changeCheck(e) {
      console.log(e, "二级id列表");
      this.city_two_id = e; //二级id列表
      this.getSearchInfo();
    },

    // 清空选项
    delCheck() {
      this.chooseIndex = "";
      (this.pageNo = 1), (this.checkList = []);
      this.city_id = "";
      this.city_two_id = [];
      this.value = "";
      this.typeId = "";
      this.valueCity = "";
      this.brokerName = '';
      this.getSearchInfo();
    },

    // 去详情页
    goBroker(item) {
      this.$router.push({
        path: `/borker_details`,
        query: {
          id: item.user_id
        }
      });
    },

    // 获取汽车品牌
    getType() {
      this.$http.getType().then(res => {
        if (res.code == 1) {
          this.typeList = res.data;

          console.log(this.typeList, "代理类型");
        } else {
          this.$messgae.info(res.msg);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped="scoped">
.active {
  color: #f39800;
}

.no_active {
  color: #333333;
}

.top_fix {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 500;
}

.big_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.all_container {
  background: #f6f7f9;
  // padding-top: 150px;
}

.container {
  width: 100%;
  // height: 446px;
  margin: 0 auto;
  padding-bottom: 64px;
}

.changeClassify {
  display: flex;
  // justify-content: space-between;
  // width: 84px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 32px 0 0 32px;

  .title {
    color: #f39800;
  }
}

.carousel {
  padding: 0 32px;
  margin-top: 36px;
  display: flex;

  .carousel_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: #fff;
    // height: 258px;
    width: 100%;
    padding: 32px;

    .carousel_box_right {
      width: 180px;
      text-align: right;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #4177dd;
      white-space: nowrap;
      cursor: pointer;
    }

   
    .cascader {
      // margin-right: 20px;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }

    .right {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-left: 40px;

      .top {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        flex-wrap: nowrap;

        .city_name {
          display: flex;
          // justify-content: center;
          flex-direction: column;
          align-items: center;
          // margin-right: 40px;

          .border {
            margin: 6px auto 0;
            width: 40px;
            height: 3px;
            background: rgba(243, 152, 0);
            border-radius: 2px;
          }
        }

        .city_name:hover {
          cursor: pointer;
        }

        .active {
          color: rgba(243, 152, 0);
        }
      }

      .center {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        flex-wrap: nowrap;
        margin: 24px 0px 33px 0px;
      }

      .bottom {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        .left {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: left;
          box-sizing: border-box;

          input {
            width: 210px;
            height: 40px;
            border: 1px solid #dedede;
            opacity: 1;
            border-radius: 4px;
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }

        .slider {
          display: flex;
          align-items: center;
          .title {
            margin-right: 30px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
          .block {
            width: 200px;
          }
        }

        .right {
          width: 180px;
          text-align: right;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4177dd;
          white-space: nowrap;
        }
      }
    }

    .right:hover {
      cursor: pointer;
    }
  }
}

// .carousel {
//   padding: 0 32px;
//   margin-top: 36px;
//   display: flex;

//   .carousel_box {
//     display: flex;
//     justify-content: left;
//     box-sizing: border-box;
//     background-color: #fff;
//     // height: 258px;
//     width: 100%;
//     padding: 32px;

//     .left {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       box-sizing: border-box;
//       font-size: 16px;
//       font-family: Microsoft YaHei;
//       font-weight: bold;
//       color: #333333;
//     }

//     .right {
//       width: 90%;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       box-sizing: border-box;
//       font-size: 16px;
//       font-family: Microsoft YaHei;
//       font-weight: 400;
//       color: #333333;
//       margin-left: 40px;

//       .top {
//         display: flex;
//         justify-content: space-between;
//         box-sizing: border-box;
//         flex-wrap: nowrap;

//         .city_name {
//           // margin-right: 40px;

//           .border {
//             width: 40px;
//             height: 3px;
//             background: rgba(243, 152, 0);
//             border-radius: 2px;
//             margin-top: 6px;
//           }
//         }

//         .city_name:hover {
//           cursor: pointer;
//         }
//       }

//       .center {
//         display: flex;
//         justify-content: space-between;
//         box-sizing: border-box;
//         flex-wrap: nowrap;
//         margin: 24px 0px 33px 0px;
//       }

//       .bottom {
//         display: flex;
//         width: 100%;
//         align-items: center;
//         justify-content: space-between;
//         box-sizing: border-box;
//         .left {
//           display: flex;
//           flex-direction: row;
//           align-items: center;
//           justify-content: left;
//           box-sizing: border-box;

//           input {
//             width: 210px;
//             height: 40px;
//             border: 1px solid #dedede;
//             opacity: 1;
//             border-radius: 4px;
//             text-align: center;
//             font-size: 16px;
//             font-family: Microsoft YaHei;
//             font-weight: 400;
//             color: #999999;
//           }
//         }

//         .right {
//           text-align: right;
//           width: 210px;
//           font-family: Microsoft YaHei;
//           font-weight: 400;
//           color: #4177dd;
//           white-space: nowrap;
//         }
//       }
//     }

//     .right:hover {
//       cursor: pointer;
//     }
//   }
// }

.main_body {
  // 信息分类
  .info {
    // padding-top: 88px;
    background: #f6f7f9;
    padding: 0 36px;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;

      .border {
        width: 40px;
        height: 4px;
        background: rgba(243, 152, 0);
        border-radius: 2px;
        margin-top: 8px;
      }
    }

    .broker_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .info_box {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 56px;
        width: 900px;
        height: 380px;
        background-color: #efefef;

        .broker_img {
          position: relative;
          width: 400px;
          height: 100%;
          background-image: url("../../../assets/particulars_icons/order.png");
          background-size: cover;

          .broker_img_m {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.3;
          }

          .border_info {
            position: absolute;
            top: 142px;
            left: 30px;
            // z-index: 888;

            .border_info_name {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
              text-align: left;
            }

            .border {
              margin-top: 24px;
              margin-bottom: 32px;
              background-color: #f39800;
              height: 4px;
              width: 80px;
            }

            .border_info_content {
              width: 202px;
              height: 115px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #ffffff;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
            }
          }
        }

        .talent_agency {
          position: absolute;
          display: flex;
          padding: 56px 0 88px 48px;
          width: 620px;
          height: 316px;
          background-color: #fff;
          right: 0;
          top: 32px;
          box-sizing: border-box;

          .left {
            img {
              width: 140px;
              height: 140px;
              border-radius: 50%;
              background-color: pink;
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 32px;
            text-align: left;

            .name {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
            }

            .job {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #8d5710;
            }

            .num {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #888888;
            }

            .address {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;

              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        .btn {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 240px;
          height: 48px;
          background: #f39800;
          border-radius: 4px;
          color: #fff;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 48px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .classify {
      position: relative;
      background-color: #fff;
      height: 460px;
      width: 100%;
      box-sizing: border-box;

      .top {
        margin-top: 32px;
        padding-top: 71px;
        display: grid;
        justify-content: center;
        grid-template-columns: 16% 16% 16% 16% 16% 16%;

        .top_box {
          position: relative;
          margin-bottom: 48px;
          margin-right: 10px;

          .img {
            position: absolute;
            top: -30%;
            left: 50%;
            transform: translatex(-60%);
            width: 70px;
            height: 70px;

            // background-color: pink;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .top_box_border {
            // width: 280px;
            height: 105px;
            border-radius: 5px;
            // background-color: pink;
            border: 1px solid #ff8100;
            border-bottom: 6px solid #ff8100;

            .title {
              margin-top: 50px;
              font-size: 22px;
              text-align: center;
            }
          }
        }
      }

      .bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 48px;
        background-image: url("../../../assets/new_home_icons/bottom.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .company {
      width: 100%;

      .company_box {
        padding: 56px 54px;
        height: 328px;

        display: grid;
        background: #fff;
        justify-content: space-between;
        grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;

        img {
          width: 180px;
          height: 96px;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  padding: 0 32px;
  margin-top: 46px !important;
  justify-content: space-between !important;

  .total {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0);
  }

  :deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
    background-color: #f39800 !important; //修改默认的背景色
  }
}

.hot_recommand {
  box-sizing: border-box;
  display: flex;
  margin-top: 16px;

  .hot_recommand_left {
    width: 232px;

    .two {
      margin-top: 16px;
    }
  }

  .hot_recommand_right {
    width: 968px;
    padding-left: 10px;

    .hot_img {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;

      img {
        width: 205px;
        height: 52px;
      }
    }

    .all_house {
      display: flex;
      flex-wrap: wrap;
    }

    .check_more {
      margin-top: 24px;

      &:hover {
        cursor: pointer;
      }

      img {
        width: 120px;
        height: 36px;
      }
    }
  }
}

.vip {
  display: flex;
  margin-top: 40px;

  .vip_left {
    width: 232px;
  }

  .vip_right {
    width: 968px;

    .vip_img {
      img {
        width: 928px;
        height: 112px;
      }
    }

    .vip_carousel {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;

      .vip_carousel_left {
        width: 474px;
      }

      .vip_carousel_right {
        width: 474px;
      }
    }

    .vip_big_carousel {
      margin-top: 10px;
      width: 958px;
    }
  }
}

.like {
  .like_img {
    margin: 40px 0 20px;

    img {
      width: 316px;
      height: 44px;
    }
  }

  .all_house {
    display: flex;
    flex-wrap: wrap;
  }
}

.carousel_left {
  width: 232px;

  /deep/ .el-carousel__arrow {
    top: 72%;
  }

  /deep/ .el-carousel__arrow--left,
  /deep/ .el-carousel__arrow--right {
    width: 24px;
    height: 24px;
  }
}

.carousel_left2 {
  width: 232px;

  /deep/ .el-carousel__arrow {
  }

  /deep/ .el-carousel__arrow--left,
  /deep/ .el-carousel__arrow--right {
    width: 24px;
    height: 24px;
  }
}

/deep/.el-carousel__indicators--horizontal {
  .el-carousel__indicator--horizontal button {
    width: 8px;
    height: 8px;
    background: #ffffff;
    border-radius: 50%;
    opacity: 0.5;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 24px;
    height: 8px;
    background: #ffffff;
    opacity: 0.5;
    border-radius: 10px;
  }
}

.pagination {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

// 新增的样式
.loading {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0rpx;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingImg {
  width: 100rpx;
  height: 100rpx;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 11;
}

/deep/.el-checkbox__inner {
  border-color: #d1d1d0;
}

// /deep/.is-checked{
//     background-color: #F39800 !important;
//     border-color: #F39800 !important;
// }
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f39800;
  border-color: #f39800;
}

/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f39800;
}


/deep/ .el-input{
      width: 200px;
    }

</style>
